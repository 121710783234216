const Text = {
    is:
`
# Stefna Tilkynna um notkun á vefkökum
Tilkynna notar vefkökur (e. cookies) sem eru litlar textaskrár geymdar á tölvu notandans, eða í öðru snjalltæki til þess að halda eðlilegri virkni á vefsíðunni.

## Hvaða upplýsingar skráum við?
Tilkynna skráir einungis upplýsingar sem eru nauðsynlegar til að halda eðlilegri virkni á vefsíðunni.

Upplýsingar sem við geymum með vefkökum eru:
- Tungumál sem er valið á vefsíðunni, t.d. _en-US_ og _is-IS_
- Tímabundna vefköku (e. session cookie) til að muna innskráningu fyrir lögaðila

## Spurningar
Ef þú hefur spurningar varðandi stefnu Tilkynna um notkun á vefkökum, þá geturðu haft samband við tæknistjóra Tilkynna, Jón Trausta Arason, netfang: jon@tilkynna.is.
`,

    en:
`
# Cookie Policy
Tilkynna uses cookies that are small text files stored on the user's computer, or in other smart device to maintain the normal functionality of the website.

## What information is stored?
Tilkynna stores only information that is required for the functionality of the website.

Information that we store with cookies:
- Information to remember the selected language, e.g. _en-US_ and _is-IS_
- Temporarily session cookie to remain signed in to an organisation

## Questions
If you have any questions about our cookie policy, then you can contact Jón Trausti Arason, email: jon@tilkynna.is.
`
}

export default Text
