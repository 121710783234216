import React from 'react'

import * as styles from './index.module.scss'

const SubpageHero = ({children}) => {
    return (
        <section className={styles.subpageHero}>
            {children}
        </section>
    )
}

export default SubpageHero
